.careerheading{
    font-size: 25px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    opacity: 0.9;
}
.careerpara{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.careerimg{
    height: 200px;
    margin-left: 50px;
}
.careerimgg{
    height: 200px;
    margin-left: 50px;
}
.sendcv{
    height: 100px;
    width: 100%;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cvtext{
    font-size: 20px;
    text-transform: capitalize;
}
.cvbutton{
    text-decoration: none;
    border: 1px solid black;
    padding: 5px;
    background-color: black;
    color: white;
border-radius: 5px;

}
@media screen and (max-width: 768px) {
    .sendcv {
        width: 100%;
    }
    .careerimgg {
        height: 171px;
        margin-right: 20px;
    }
    .careerheading{
        font-size: 18px;
        text-align: center;
    }
    .careerpara{
        text-align: justify;
    }
}