.fssize {
    font-size: 40px;
    color: black;
}

.mainnavbar {
    position: absolute;
    position: fixed;
    top: 60px;
    width: 100%;
    z-Index: 999;
    padding: 10px 15px;
}

.pdfcolor {
    height: 40px;
    margin-top: -20px;
}

.parent-list {
    list-style-type: none;
    margin: 0 auto;
}

.child-list {
    position: absolute;
    left: 213px;
    top: 0px;
    background: #F0F0F0;
    list-style-type: none;
    display: none;
    z-index: 1;
    height: 200px;
    width: 150px;
}

.child-list li {
    padding: 2px;
}

.child-list li a {
    color: black;
    text-decoration: none;
    margin-left: -20px;

}

.parent-list:hover .child-list {
    display: block;
}

.icon-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.icon-containerdiv {
    height: 50px;
    width: 50px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.navbaralllink {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-item:hover {
    /* background-color: #00abed; */
    border-radius: 5px;
    color: black;
    /* font-weight: 00; */
}

.nav-link:hover {
    background-color: #00abed;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.navbar-nav {
    gap: 20px;
}

.navbardivfirst {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    border: 1px solid black;
}

.navbartopheanding {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
}

.fssizee {
    font-size: 40px;
    color: #00abed;
}

.fssizeee {
    font-size: 20px;
    color: #00abed;
}

.navbaespantext {
    font-size: 15px;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.navbardivsecond {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 50px 5px 50px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.navbarimage {
    height: 55px;
}

.nav-link.dropdown-toggle::after {
    display: none;
}

@media screen and (max-width: 768px) {
    .parent-list:hover .child-list {
        display: none;
    }

    .nav-item.dropdown {
        position: relative;
    }
    .mainnavbar {
        top: 40px;
    }
    .nav-link.dropdown-toggle::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
        z-index: 1;
        position: absolute;
        top: 10px;
    }

    .child-list {
        left: 199px;
        top: 51px;
    }


    /* ---- Mobile navbar  */
    .icon-container {
        display: none;
    }

}

@media only screen and (max-width: 600px) {
    .fssize {
        font-size: 20px;
    }
    .fssize {
        font-size: 20px;
    }
    .pdfcolor {
        height: 20px;
        margin-top: -5px;
    }

    .navbardivfirst {
        display: flex;
        justify-content: space-between;
    }

    .navbartopheanding {
        margin-top: 5px;
        font-size: 10px;
        font-weight: 500;
    }

    .fssizee {
        font-size: 20px;
        color: #00abed;
    }

    .fssizeee {
        font-size: 15px;
        color: #00abed;
    }

    .navbaespantext {
        font-size: 10px;
        color: black;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .navbardivsecond {
        border: 1px solid black;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 0px;
        padding: 0px 20px;
    }

    .navbarimage {
        height: 40px;
    }

    .navbar-nav {
        display: flex;
        justify-content: start;
        align-items: start;
        gap: 1px;
    }

    .navbar-toggler-icon {
        width: 1rem;
        height: 1rem;
    }

    .navbar-toggler {
        margin-left: 85%;
        margin-top: -9%;
    }

    .navbaralllink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .nav-link.dropdown-toggle::after {
        display: none;
    }

    .call {
        display: none !important;
    }
}